.myckeditor2 ol li {
  list-style: decimal !important;
  margin-left: 20px;
}

.myckeditor2 ul li {
  list-style: inside !important;
}

.fileandDis {
  width: 100% !important;
}
.MyCkeditorDDbtn {
  height: 43px !important;
  width: 90% !important;
  margin-top: 7px !important;
  margin-left: -7px !important;
}
.MyCkeditorDDbtn svg {
  margin-top: -5px;
}
.disLim {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-align: justify !important;
  width: 100%;
  border: none !important;
  text-align: center !important;
  /* padding-bottom: 1px; */
}
.disLimR{
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-align: justify !important;
  width: 90%;
  margin: auto;
  border: none !important;
  margin-bottom: 20px;
}
.galleryMidalDropdown {
  width: 95% !important;
  margin: auto !important;
  border: 1px solid #c9c7cf;
}

.galleryDropdown {
  margin-top: 0px !important;
  margin-left: 30px !important;
  margin-bottom: 2px !important;
  width: 95% !important;
}

.imageDiv:hover .showBtn{
  display: block !important;
}

.showBtn{
  display: none;
}


