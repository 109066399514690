.gallery #myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.gallery #myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.gallery .modal {
  display: none;
  position: fixed;
  z-index: 1000000000000 !important;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

/* Modal Content (image) */
.gallery .modal-content {
  margin: auto;
  display: block;
  width: 95%;
  max-width: 1000px;
}

/* Caption of Modal Image */
.gallery #caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.gallery .modal-content,
.gallery #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.gallery .close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.gallery .close:hover,
.gallery .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .gallery .modal-content {
    width: 100%;
  }
}
