.box {
  display: flex;
  gap: 50px;
  margin: 50px 20px;
}

.left-box {
  height: 60vh;
  width: 40%;
  /* border: 0.5px solid rgb(108, 107, 107); */
  border-radius: 5px;
  background-color: rgb(226 232 240);
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.left-box img {
  height: 130px;
  width: 160px;
  object-fit: cover;
  position: relative;
  /* margin: 30px auto 10px auto; */
}

.content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -50px;
}

.left-box h2 {
  font-weight: 1000;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: xx-large;
  text-align: center;
}

.left-box h3 {
  font-weight: 1000;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: x-large;
  text-align: center;
  margin-top: 10px;
}

.left-box p {
  text-align: center;
  margin: 20px;
  font-size: medium;
  font-family:Verdana, Geneva, Tahoma, sans-serif; 
}

.right-box {
  height: 60vh;
  width: 55%;
  position: relative;
  border-radius: 5px;
  background-color: rgb(226 232 240);
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.main {
  width: 90%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  margin: 60px auto;
}

.div {
  display: flex;
  justify-content: space-between;
}

#button {
  border: 1px solid rgb(113, 111, 111);
  /* position: absolute; */
  background: linear-gradient(266deg,rgb(48, 99, 151) 8%,rgb(5, 58, 111) 71%);
  color: white;
  /* bottom: 10%; */
  right: 35px;
  cursor: pointer;
  border-radius: 4px;
}
.containers{
  width: 83%;
}