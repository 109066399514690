nav {
  height: 8vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
#logo {
  margin-left: 50px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.6rem;
  font-weight: 600;
  z-index: 100;
  color: white;
}
.navBar {
  width: 50%;
  display: block;
  z-index: 1;
}
.navBar a {
  font-size: 14px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  padding: 4px !important;
}
.navBar .subcat a {
  padding: 10px 6px !important;
  font-size: 13px !important;
}
.nav_links {
  display: flex;
  list-style: none;
  justify-content: space-around;
  transition: 0.5s;
}
.nav_links a {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  padding: 15px 20px;
}
.active,
.nav_links a:hover {
  color: rgb(255, 255, 255);
}

.hamBurger {
  position: absolute;
  right: 35px;
  height: 30px;
  width: 30px;
  display: none;
  cursor: pointer;
  z-index: 10;
  position: relative;
  scale: 0.8;
}
.hamBurger .lines {
  transition: 0.3s !important;
  height: 3px;
  margin: 6px;
  width: 100%;
  background-color: #041962;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.nav_links_mini {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 50%;
  justify-content: center;
  gap: 20px;
  align-items: center;
  transition: 0.5s;
  height: 100vh;
  background-color: #041962;
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  color: white;
  z-index: 10000000000000000000000000000000000000;
}
.nav_links_mini a {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  padding: 15px 20px;
}
.c-Black {
  background-color: black !important;
}
.c-White {
  background-color: white !important;
}
.fc-White {
  color: white;
}

.crossBar1 {
  transform: rotateZ(45deg);
  position: absolute;
  top: 10px;
}
.crossBar2 {
  transform: rotateZ(135deg);
  position: absolute;
  top: 10px;
}
.crossBar3 {
  display: none;
}

.dropDown {
  position: relative;
}
.dropDownLinks {
  display: none;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: rgb(210, 242, 255);
}
.dropDownLinks::after {
  content: "";
  position: absolute;
  top: -5px;
  right: 0;
  bottom: 0;
  left: 10px;
  height: 10px;
  width: 10px;
  transform: rotateZ(45deg);
  background-color: rgb(125, 182, 231);
  z-index: -1;
}
.dropDown:hover > .dropDownLinks {
  display: block;
}
.dropDownLinks li {
  list-style: none;
}
.dropDownLinks li a {
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
  letter-spacing: 1px;
  display: block;
  background-color: rgb(125, 182, 231);
  padding: 10px 25px;
  font-weight: 600;
}
.dropDownLinks li a:hover {
  color: rgb(0, 0, 0);
  background-color: rgb(17, 59, 70);
  color: white;
}
@media screen and (max-width: 1050px) {
  .dropDownLinks li a {
    padding: 7px 15px;
    font-size: 14px;
  }
  .nav_links_mini a:hover {
    color: rgb(255, 255, 255);
  }
  .hamBurger {
    display: block;
  }
  .nav_links {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 50%;
    justify-content: space-around;
    align-items: center;
    transition: 0.5s;
    height: 100vh;
    background-color: #041962;
    position: absolute;
    top: -30px;
    right: 0;
    bottom: 0;
    color: white;
    transform: translateY(-100vh);
  }
  .dropDownLinks li {
    color: rgba(255, 255, 255, 0.7);
  }
  .dropDownLinks li:hover {
    color: white;
  }
}

@media screen and (max-width: 650px) {
  .nav_links_mini {
    width: 100%;
  }
}
