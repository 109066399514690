#categoryOrdering,
#categoryAction {
  width: 20%;
  border-left: 0 !important;
}
#category {
  height: 80vh !important;
}
#action {
  font-size: 20px;
  cursor: pointer;
  position: relative;
}
.actionIcons {
  height: 25px;
  width: 25px;
  background: linear-gradient(
    266deg,
    rgb(48, 99, 151) 8%,
    rgb(24, 77, 130) 71%
  );
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
}
.actionIcons:hover {
  scale: 1.1;
}
.icons {
  position: absolute;
  font-size: 18px;
}
.tableAction {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

#heading, 
#content {
  border: 1px solid rgb(0, 0, 0);
}
#content {
  border: 1px solid rgb(183, 183, 183);
  border-top: transparent;
}
#heading div,
#content div {
  padding: 8px;
  color: black !important;
  letter-spacing: 1px;
}
#content div {
  padding: 5px !important;
  font-weight: 400 !important;
  letter-spacing: 1px;
}
#discontent div {
  padding: 50px;
  font-weight: 600;
  letter-spacing: 1px;
}

#discription {
  height: 200px;
  border-top: 0;
}
.discontent {
  border-top: 0;
}
.addbutton {
  background: linear-gradient(
    266deg,
    rgb(35, 74, 112) 8%,
    rgb(24, 77, 130) 71%
  );
  color: white;
  font-size: 14px;
}
.addbutton:hover {
  background: rgb(48, 99, 151);
}
.subcatClass{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 95%;
  margin: auto;
  border: none !important;
  padding-top: -10px;
  margin-bottom: 20px;
}