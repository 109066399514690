@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
  max-width: 1600px !important;
  margin: 0 auto;
}
/* .swiper-pagination-bullet {
} */
.swiper-pagination-bullet-active {
  color: #041962 !important;
  background-color: #041962 !important;
}
.subcat:hover .subsubCat {
  display: block !important;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}

#dynamiccourses {
  text-align: justify !important;
  width: 100%;
}

#dynamiccourses p {
  display: inline; /* This might not be necessary for justification */
  width: 100%;
}
#dynamiccourse {
  text-align: justify !important;
  width: 100%;
}

#dynamiccourse p {
  display: inline; /* This might not be necessary for justification */
  width: 100%;
}
.sliderDynamic p {
  display: inline;
  width: 100%;
}
#dynamiccourses a {
  margin-left: 5px;
}
#dynamiccourse a {
  margin-left: 5px;
}
.noticeCkeditor .cke {
  width: 80% !important;
}
.paracontent p {
  text-align: justify;
  width: 100%;
}
.description-container {
  max-width: 800%;
  margin: 10px auto 20px;
}

.description-container .floating-image {
  width: 350px;
  max-height: 350px;
  object-fit: cover;
}

.description-container .floating-image.right {
  float: right;
}
.swiper-pagination-bullet {
  background-color: white !important;
  height: 9px !important;
  width: 9px !important;
  transition: 0.5s;
}
.swiper-pagination-bullet-active {
  width: 20px !important;
  border-radius: 3px !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  scale: 0.7 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

@media screen and (max-width: 800px) {
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    scale: 0.4 !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
}
.Toastify__toast,
.Toastify__toast-container,
.Toastify {
  z-index: 5000000000000000 !important;
}
.Toastify div {
  z-index: 5000000000000000 !important;
}
.Toastify__toast--warning {
  background-color: #e74c3c !important;
}
.Toastify__toast--error {
  background-color: #f1c40f !important;
}
