
.subcategory{
    position: relative;
}
.category:hover .subcategory{
    display: block;
}
.dropdownbtn{
    width: 400px;
    margin-top: 10px !important;
    margin-left: 8px !important;
    border-radius: 2px !important;
    text-align: start !important;
    height: 50px !important;
    margin-bottom: 20px ;
    background-color: white;
    color: black;
}
.videosDropdownmanual{
    width: 555px !important;
    margin-left: -15px !important;
}
.dropbtn21{
    width: 100% !important;
}
.dropdownbtn li{
    padding: 10px;
    cursor: pointer;
}
.dropdownbtn li:hover{
    background-color: #689fff;
}

.subcategory{
    display: none;
    /* height: 200px;
    width: 300px; */
    background-color: blue;
    position: absolute;
    right: 0;
    top: 5px;
}
.category{
    position: relative;
}
.subcategory{
    position: absolute;
    right: -100%;
    width: 100%;
}
.subcategory li{
    position: relative;
}
.subcategory li:hover .subsubcategory{
    display: block;
}
.subsubcategory{
    top: 5px;
    display: none;  
    position: absolute; 
    right: -100%;
    width: 100%;
    
}
.dropdownbtn:hover{
    background-color: white !important;
    color: black !important;
}
.btn.show{
    background-color: white !important;
    color: black !important;
}
#videosDropdownmanual{
    width: 555 !important;
}